import { Injectable,Inject } from '@angular/core';
import { HttpClient,HttpHeaders, HttpRequest } from '@angular/common/http';
import jsSHA from 'jssha';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
 
@Injectable({
  providedIn: 'root'
})
export class PokemonService {
  baseUrl =  'https://api.allrideyrentalsqueenstown.com';
  imageUrl = 'https://api.allrideyrentalsqueenstown.com';
  
  RCMbookingurl="https://apis.rentalcarmanager.com/booking/v3.2/";
  bookurl="https://api.allrideyrentalsqueenstown.com";
  constructor(private http: HttpClient,@Inject(DOCUMENT) private dom) {}
 
 
  getPokemon(offset = 0) {
    return this.http
      .get(`${this.baseUrl}/vehicles`)
      .pipe(
        map(result => {
          return result['results'];
        }), 
      map(pokemon => {
          return pokemon.map((poke, index) => {
            poke.image = this.getPokeImage(offset + index + 1);
            poke.pokeIndex = offset + index + 1;
            return poke;
          }); 
        })
      );
  }
  
  getCar(offset = 0) {
    return this.http
      .get(`${this.baseUrl}/v3/vehicles/list/?category=car-hire`)
      .pipe(
        map(result => {
          return result['results'];
        }),
      );
  }
  getVehicles() {
    return this.http
      .get(`${this.baseUrl}/vehicles/`)
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
  getCampervan(offset = 0) {
    return this.http
      .get(`${this.baseUrl}/v3/vehicles/list/?category=campervan-hire`)
      .pipe(
        map(result => {
          return result['results'];
        }),
      );
  }
  getawd(offset = 0) {
    return this.http
      .get(`${this.baseUrl}/v3/vehicles/list/?category=4wd-rental`)
      .pipe(
        map(result => {
          return result['results'];
        }),
      );
  }


  getLocCat(index) {
    return this.http.get(`${this.baseUrl}/v3/location/category/${index}`)
	 .pipe(
      map(poke => {
		//let loc = Object.keys(poke['address']);
        //poke['loci'] =loc.map(spriteKey => poke['address'][spriteKey]);
        return poke;
      })
    );
  }
   getSlider(offset = 0){
	  return this.http
      .get(`${this.baseUrl}/v2/homeslide/?offset=${offset}&limit=0`)
      .pipe(
        map(result => {
          return result['results'];
        }),
        
      );
	  
  }
  
  getMenu(offset = 0){
	  return this.http
      .get(`${this.baseUrl}/menu`)
      .pipe(
        map(result => {
          return result['menu'];
        }),
        
      );
	  
  }

  getPromo(offset = 0){
	  return this.http
      .get(`${this.baseUrl}/promo`)
      .pipe(
        map(result => {
          return result['results'];
        }),
        
      );
  }

  //flash sale alert
  
  getAlert(offset = 0){
	  return this.http
      .get(`${this.baseUrl}/promo/top`)
      .pipe(
        map(result => {
          return result;
        }),
        
      );
  }
  
  getWeather(city="all",daily = false,range=0){
    var urlx=`https://api.wickedcampers.com.au//v3/weather/?daily=${daily}`;
    if(city!="all") urlx+="&loc="+city;
    if(range>0) urlx+="&range="+range;
	  return this.http
      .get(urlx)
      .pipe(
        map(result => {
          return result;
        }),
        
      );
  }

  getWeatheradelaide(city="all",daily = false,range=0){
    var urlx=`${this.baseUrl}/v3/weather/?daily=${daily}`;
    if(city!="all") urlx+="&loc="+city;
    if(range>0) urlx+="&range="+range;
	  return this.http
      .get(urlx)
      .pipe(
        map(result => {
          return result;
        }),
        
      );
  }


    //trip
    getTrip(cat="all"){
      let urlx=`${this.baseUrl}/trip`;
      if(cat!="all"){
        urlx+="?category="+cat;
      }
      return this.http
        .get(urlx)
        .pipe(
          map(result => {
            return result;
          }),
          
        );
    }

    getTripDetails(index) {
      return this.http.get(`${this.baseUrl}/trip/detail/${index}`)
     .pipe(
        map(poke => {
          return poke;
        })
      );
    }

  

/*
  getPromoDetails(index) {
    return this.http.get(`${this.baseUrl}/v3/promo/detail/${index}`)
	 .pipe(
      map(poke => {
		//let loc = Object.keys(poke['address']);
        //poke['loci'] =loc.map(spriteKey => poke['address'][spriteKey]);
        return poke;
      })
    );
  }
 */
  findPokemon(search) {
    return this.http.get(`${this.baseUrl}/v3/vehicles/list/${search}`)
	 .pipe(
      map(pokemon => {
        pokemon['thumb'] = this.getPokeImage(pokemon['id']);
        pokemon['pokeIndex'] = pokemon['url'];
        return pokemon;
      })
    );
  }
 
  getPokeImage(index) {
    return `${this.imageUrl}${index}.jpg`;
  }
 
  getPokeDetails(index) {
    return this.http.get(`${this.baseUrl}/vehicles/detail/${index}`)
	 .pipe(
      map(poke => {
		let slides = Object.keys(poke['slides']);
        poke['images'] = slides
          .map(spriteKey => poke['slides'][spriteKey]['url'])
          .filter(img => img);
        return poke;
      })
    );
  }
  
    getAVailloc(index) {
    return this.http.get(`${this.baseUrl}/v3/vehicles/detail/${index}`)
	 .pipe(
      map(poke => {
		let loc = Object.keys(poke['available_locations']);
        poke['loci'] =loc.map(spriteKey => poke['available_locations'][spriteKey]);
        return poke;
      })
    );
  }
  
	getAVaillocDetails(index) {
    return this.http.get(`${this.baseUrl}/location/detail/${index}`)
	 .pipe(
      map(poke => {
		//let loc = Object.keys(poke['address']);
        //poke['loci'] =loc.map(spriteKey => poke['address'][spriteKey]);
        return poke;
      })
    );
  }
  
  getAVailDetails(index) {
    return this.http.get(`${this.baseUrl}/v3/vehicles/detail/${index}`)
	 .pipe(
      map(poke => {
		let loc = Object.keys(poke['specification']);
        poke['locic'] =loc.map(spriteKey => poke['specification'][spriteKey]);
        return poke;
      })
    );
  }

  getLocations(offset = 0) {
    return this.http
      .get(`${this.baseUrl}/location/`)
      .pipe(
        map(result => {
          return result;
        }),
        /*map(pokemon => {
          return pokemon.map((poke, index) => {
            poke.image = this.getPokeImage(offset + index + 1);
            poke.pokeIndex = offset + index + 1;
            return poke;
          });
        })*/
      );
  }
  
  getKomen(offset = 0) {
    return this.http
      .get(`${this.baseUrl}/v3/review`)
      .pipe(
        map(result => {
          return result['results'];
        }),
        
      );
  }
  getPokeLocations(index) {
    return this.http.get(`${this.baseUrl}/location/detail/${index}`)
	 .pipe(
      map(poke => {
        /*
        let slides = Object.keys(poke['slides']);
        poke['images'] = slides
          .map(spriteKey => poke['slides'][spriteKey]['url'])
          .filter(img => img);
        */
        return poke;
      })
    );
  }
  



  getMenugroup(offset = 0){
    return this.http.get(`${this.baseUrl}/menu/group/footer/?offset=${offset}&limit=0`)
    .pipe(
      map(result => {
        return result['results'];
      }),
    );
  }
  
  getPagesDetail(index) {
    return this.http.get(`${this.baseUrl}/staticpage/detail/${index}`)
	 .pipe(
      map(poke => {
    /*
        let slides = Object.keys(poke['slides']);
        poke['images'] = slides
          .map(spriteKey => poke['slides'][spriteKey]['url'])
          .filter(img => img);
         */ 
        return poke;
      })
    );
  }
  /*
  getSpecials(offset = 0) {
    return this.http
      .get(`${this.baseUrl}/v3/special/list`)
      .pipe(
        map(result => {
          return result['results'];
        }),
        
      );
  }
  getSpecialsDetail(index) {
    return this.http.get(`${this.baseUrl}/v3/special/detail/${index}`)
	 .pipe(
      map(poke => {
   
        return poke;
      })
    );
  }
  */
  getSpecialsCat(index) {
    return this.http.get(`${this.baseUrl}/special/category/${index}`)
	 .pipe(
      map(poke => {
        return poke;
      })
    );
  }
  getBlog() {
    return this.http
      .get(`${this.baseUrl}/blog`)
      .pipe(
        map(result => {
          return result;
        }),
        
      );
  }
  getBlogTags(index) {
    return this.http
      .get(`${this.baseUrl}/blog?tags=${index}`)
      .pipe(
        map(result => {
          return result;
        }),
        
      );
  }
  getBlogCat(index) {
    return this.http
      .get(`${this.baseUrl}/blog?category=${index}`)
      .pipe(
        map(result => {
          return result;
        }),
        
      );
  }
  getBlogDetails(index) {
    return this.http.get(`${this.baseUrl}/blog/detail/${index}`)
	 .pipe(
      map(poke => {
   
        return poke;
      })
    );
  }

  
  //bookings 
  bookingGetVehDetail(rcmid) {
    return this.http
      .get(`${this.bookurl}/bookings/getvehicledata/${rcmid}`)
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
  bookingGetSlider(rcmid) {
    return this.http
      .get(`${this.bookurl}/bookings/getslider/${rcmid}`)
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
  bookingGetvcat() {
    return this.http
      .get(`${this.bookurl}/bookings/getcatlist`)
      .pipe(
        map(result => {
          return result;
        }),
      );
  }

  rcmgetkey(){
    // let key={
    //   key:"QXVBbGxSaWRleTUzNFt1bmRlZmluZWRdfGJ1ZGlAd2lja2VkY2FtcGVycy5jb20uYXV8NDFBOHpIMkQ=",
    //   secret:"E3aINe5eNzinxBaHdu6fPmdwtdALsLbs"
    // };
    let key={
      key:"dW5kZWZpbmVkW3VuZGVmaW5lZF18dW5kZWZpbmVkfDdJdzdydmRy",
      secret:"80wdsqtFgAImeD7dGja2wKDBqPgm2Js1"
    };
    return key;
  }
  getRCMLocation(country="au"){
    let key=this.rcmgetkey();
    let signature:any;
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update('{"method":"step1"}');
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request={"method":"step1"}';
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
        
    );
  }
  getRCMAge(country="au"){
    let key=this.rcmgetkey();
    let signature:any;
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update('{"method":"driverages"}');
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request={"method":"driverages"}';
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result['results'];
        }),
        
    );
  }
  getRCMVehicles(data){
    let key=this.rcmgetkey();
    let signature:any;
    let method='{"method":"step2","vehiclecategorytypeid":"0","pickuplocationid":'+data.pickuploc+',"pickupdate":"'+data.pickupdate+'","pickuptime":"'+data.pickuptime+'","dropofflocationid":'+data.dropoffloc+',"dropoffdate":"'+data.dropoffdate+'","dropofftime":"'+data.dropofftime+'","ageid":'+data.age+',"campaigncode":"'+data.voucher+'"}';
    console.log("method",method);
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update(method);
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request='+method;
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  getRCMExtras(data){
    let key=this.rcmgetkey();
    let signature:any;
    let method='{"method":"step3","vehiclecategorytypeid":'+data.catid+',"pickuplocationid":'+data.pickuploc+',"pickupdate":"'+data.pickupdate+'","pickuptime":"'+data.pickuptime+'","dropofflocationid":'+data.dropoffloc+',"dropoffdate":"'+data.dropoffdate+'","dropofftime":"'+data.dropofftime+'","ageid":'+data.age+',"vehiclecategoryid":'+parseInt(data.id)+',"campaigncode":"'+data.voucher+'"}';
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update(method);
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request='+method;
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  getRCMcalcTotal(data,mandatory="",opt=""){
    let key=this.rcmgetkey();
    let signature:any;
    let optmethod="";
    if(mandatory!=""){
      optmethod=',"mandatoryfees":'+mandatory
      
    }
    if(opt!=""){
      optmethod=optmethod+',"optionalfees":'+opt;
    }
    console.log(optmethod);
    let method='{"method":"calctotal","pickuplocationid":'+data.locid+',"pickupdate":"'+data.pickupdate+'","vehiclecategoryid":'+data.carid+',"numberofdays":'+data['noofday']+',"totalrateafterdiscount":'+data.total+',"freedaysamount":'+data.freedays+',"insuranceid":'+data.insuid+',"extrakmsid":'+data.kmsid+optmethod+'}';
    console.log(method);
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update(method);
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request='+method;
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  getRCMSaveBooking(data,customer="",opt=""){
    let key=this.rcmgetkey();
    let signature:any;
    let optmethod="";
    //console.log("refidserv:"+sessionStorage.getItem('refid'));
    if(sessionStorage.getItem('refid')){
      optmethod=optmethod+',"referralid":'+sessionStorage.getItem('refid');
    }
    if(customer!=""){
      optmethod=optmethod+',"customer":'+customer
      
    }
    if(opt!=""){
      optmethod=optmethod+',"optionalfees":'+opt;
    }
    //console.log(optmethod);
    let method='{"method":"booking","vehiclecategorytypeid":'+data.vehiclecategorytypeid+',"pickuplocationid":'+data.pickuplocationid+',"pickupdate":"'+data.pickupdate+'","pickuptime":"'+data.pickuptime+'","dropofflocationid":'+data.dropofflocationid+',"dropoffdate":"'+data.dropoffdate+'","dropofftime":"'+data.dropofftime+'","ageid":'+data.ageid+',"vehiclecategoryid":'+data.vehiclecategoryid+',"bookingtype":'+data.bookingtype+',"insuranceid":'+data.insuranceid+',"extrakmsid":'+data.extrakmsid+',"transmission":'+data.transmission+',"numbertravelling":'+data.numbertravelling+',"remark":"'+data.remark+'","areaofuseid":"'+data.areaofuseid + '","campaigncode":"' + data.campaigncode +'","flightin":"'+data.flightin+'","flightout":"'+data.flightout+'","emailoption":"'+data.emailoption+'","foundusid":"65"'+optmethod+'}';
    //console.log(method);
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update(method);
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request='+method;
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  setRCMConfirmpayment(payres,paytype="Payment"){
    let key=this.rcmgetkey();
    let signature:any;
    let txt1=payres['cardexpiry'];
    let txt2 = txt1.slice(0, 2) + "/" + txt1.slice(2);
    //payres['transtype']
    //console.log(optmethod);
    let method='{"method":"confirmpayment","reservationref":"'+payres['reservationref']+'","amount":'+payres['amount']+',"success":true,"paytype":"'+payres['paytype']+'","paydate":"'+payres['paydate']+'","supplierid":2,"transactid":"'+payres['transactid']+'","dpstxnref":"'+payres['dpstxnref']+'","cardholder":"'+payres['cardholder']+'","paysource":"Windcave WickedAU","cardnumber":"'+payres['cardnumber']+'","cardexpiry":"'+txt2+'","transtype":"'+ paytype +'","payscenario":1,"emailoption":1}';
    
    console.log(method);
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update(method);
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request='+method;
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  getRCMBookingInfo(resref){
    let key=this.rcmgetkey();
    let signature:any;
    let method='{"method":"bookinginfo","reservationref":"'+resref+'"}';
    console.log(method);
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update(method);
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request='+method;
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }

  getRCMvaulturl(resref){
    let key=this.rcmgetkey();
    let signature:any;
    let method='{"method":"getvaulturl","reservationref":"'+resref+'"}';
    console.log(method);
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update(method);
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request='+method;
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  saveRCMvault(resref,data){
    let key=this.rcmgetkey();
    let signature:any;
    let method='{"method":"vaultentry","reservationref":"'+resref+'","data":"'+data+'"}';
    console.log(method);
    var shaObj = new jsSHA("SHA-256","TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update(method);
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded');
    let body='signature='+signature+'&request='+method;
    return this.http
      .post(this.RCMbookingurl+key.key+'?apikey='+key.key,body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  
  getBookingSetting() {
    return this.http
      .get(`${this.baseUrl}/bookings/getsetting`)
      .pipe(
        map(result => {
          return result;
        }),
      );
  }

  //checkin
  getCheckinToken(){
    return this.http.get(`${this.baseUrl}/v3/checkin/gettoken`)
	 .pipe(
    map(result => {
      return result;
    })
    );
  }
  findbooking(token,email,bookno){
    let method='{"method":"findbooking","reservationno":"'+bookno+'","email":"'+email+'"}';
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded').set('Authorization',`Bearer ${token}`);
    let body=method;
    return this.http
      .post('https://api.rentalcarmanager.com/v32/api',body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  getworkflow(token,resref){
    let method='{"method":"workflowchecklist","workflowcode":"checkin","reservationref":"'+resref+'"}';
    
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded').set('Authorization',`Bearer ${token}`);
    let body=method;
    return this.http
      .post('https://api.rentalcarmanager.com/v32/api',body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  editbookingrcm(token,data){
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded').set('Authorization',`Bearer ${token}`);
    let body=data;
    return this.http
      .post('https://api.rentalcarmanager.com/v32/api',body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  savedocument(token,data){
    let method=JSON.stringify(data);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded').set('Authorization',`Bearer ${token}`);
    let body=method;
    return this.http
      .post('https://api.rentalcarmanager.com/v32/api',body,{headers: headers,})
      .pipe(
        map(result => {
          return result;
        }),
    );
  }
  signdocument(token,data){
    let method='{"method":"signupload","params":'+JSON.stringify(data)+'}';
    console.log(token);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','application/x-www-form-urlencoded').set('Authorization',`Bearer ${token}`);
    let body=method;
    return this.http
      .post('https://api.rentalcarmanager.com/v32/api',body,{headers: headers,})
      .pipe(
        map(result => {
          console.log(result);
          return result;
        }),
    );
  }
  cloudinaryupload(data,time,sign){
    console.log(data);
    const formData: FormData = new FormData();
    const current = new Date();
    var timestampx:any=current.getTime();
    //test var
    /*var shadata=`timestamp=${timestampx}&upload_preset=ml_defaultfwFupOe8gyrdW4Yfpk__QGYm2OQ`
    var shaObj = new jsSHA("SHA-1","TEXT");
    shaObj.update(shadata);
    var signature = shaObj.getHash("HEX");
    formData.append("api_key", "276363376599961");
    formData.append("timestamp", timestampx);
    formData.append("signature", signature);
    formData.append('upload_preset', 'ml_default');*/
    //end test
    formData.append('file', data,data['name']);
    formData.append("api_key", "128229988586749");
    formData.append('upload_preset', 'hsfepzh0');
    formData.append("timestamp", time);
    formData.append("signature", sign);
    
    
    return this.http
      .post('https://api.cloudinary.com/v1_1/rentalcarmanager/upload', formData)
      .pipe(
        map(result => {
          return result;
        }),
    );

  }
  getmaplist() {
    return this.http
      .get(`https://www.ozcampervanrental.com/newapi/v2/location/maplist/`)
      .pipe(
        map(result => {
          return result['results'];
        }),
      );
  }

  setLinkRel(url:string,tag:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='`+tag+`']`) || null
    if (element==null) {
      //console.log("rel nul;",tag);
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel',tag)
    element.setAttribute('href',url)
  }
  getinstafeed() {
    
    const req = new HttpRequest('GET', 'https://www.instagram.com/wickedcampers');
    return this.http.request(req);
  }
  getsearchlist(){
    return this.http.get('https://bookings.wickedcampers.com.au/newapi/v2/search/list')
      .pipe(
        map((response:[]) => response.map(item => item))
      )
  }
  getsearch(query){
    return this.http.get('https://bookings.wickedcampers.com.au/newapi/v2/search/query?query='+query)
      .pipe(
        map(result => {
          return result;
        })
      )
  }
  //stripe express js
  getstripepayment(nominal, currrency, email, phone, name, mode,desc) {
    let method = '{"email":"' + email + '","phone":"' + phone + '","name":"' + name + '","nominal":"' + nominal + '","currency":"' + currrency + '","description":"' + desc  + '","mode":"' + mode + '"}';
    let body = method;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .post(`${this.baseUrl}/stripe/createpintent`, body, { headers: headers, })
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
  updatestripepayment( intentid,description, mode) {
    let method = '{"intentid":"' + intentid + '","description":"' + description + '","mode":"' + mode + '"}';
    let body = method;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .post(`${this.baseUrl}/stripe/updatepintent`, body, { headers: headers, })
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
  getstripepaydetail( pmid, mode) {
    let method = '{"pmid":"' + pmid + '","mode":"' + mode + '"}';
    let body = method;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .post(`${this.baseUrl}/stripe/getpaydetail`, body, { headers: headers, })
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
  getsetupintent( nominal, currrency, email, phone, name, mode) {
    let method = '{"email":"' + email + '","phone":"' + phone + '","name":"' + name + '","nominal":"' + nominal + '","currency":"' + currrency + '","mode":"' + mode + '"}';
    let body = method;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .post(`${this.baseUrl}/stripe/setupintent`, body, { headers: headers, })
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
  getintentdetail(pmid, mode) {
    let method = '{"pmid":"' + pmid + '","mode":"' + mode + '"}';
    let body = method;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http
      .post(`${this.baseUrl}/stripe/detailintent`, body, { headers: headers, })
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
  allRcmfc(data) {
    let method = JSON.stringify(data);
    let key = this.rcmgetkey();
    let signature: any;
    console.log(method);
    var shaObj = new jsSHA("SHA-256", "TEXT");
    shaObj.setHMACKey(key.secret, "TEXT");
    shaObj.update(method);
    signature = shaObj.getHMAC("HEX");
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    let body = 'signature=' + signature + '&request=' + method;
    return this.http
      .post(this.RCMbookingurl + key.key + '?apikey=' + key.key, body, { headers: headers, })
      .pipe(
        map(result => {
          return result;
        }),
      );
  }
}
