import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderFloatComponent } from './header-float.component';
import { RouterModule } from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatNativeDateModule,MAT_DATE_LOCALE} from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import {MatInputModule} from '@angular/material/input';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';


@NgModule({
  declarations: [HeaderFloatComponent],
  imports: [
    CommonModule,
    IonicModule,MatDatepickerModule,MatFormFieldModule,MatNativeDateModule,MatMomentDateModule,MatInputModule,
    FormsModule, ReactiveFormsModule,OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    RouterModule
  ],
  providers:[
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  exports: [HeaderFloatComponent]
})
export class HeaderFloatModule { }
