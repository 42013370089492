import { Component,ViewEncapsulation, ElementRef, OnInit,HostListener} from '@angular/core';
import { PokemonService } from './services/pokemon.service';
import { Platform, AlertController } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import{ GlobalConstants } from './common/global-constants';
import {  Title, Meta } from '@angular/platform-browser';
import { Router,NavigationStart ,NavigationEnd, ActivatedRoute, NavigationExtras } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import * as moment from 'moment';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  offset = 0;
  pokemon = [];	
  alert:any=[];
  data: any;
  url: any;
  showmenu:boolean=true;
  public selectedIndex = 0;
  anio: number = new Date().getFullYear();
  isBrowser: any;
  isServer: any;
  public appPages = [
    {
      title: 'Home',
      url: '',
    },
   
  ];
  stepAll={
    pickuploc:'0',
    pickupdate:'',
    pickupdatem:'',
    pickuptime:'',
    pickuptimem:'',
    dropoffloc:'0',
    dropoffdate:'',
    dropoffdatem:'',
    dropofftime:'',
    dropofftimem:'',
    voucher:'',
    age:''
  };
  booklocation: any=[];
  Age:any;
  bookdata: any;
  bookurl: any;
  agexx=0;
  deflocval="";
  showform=false;
  public minDate = moment().add(0, 'd').format();
  public maxDate = moment().add(5, 'y').format();

  dayx = new Date();

  public mindateDS= moment(this.dayx.setHours(10,0,0)).format();
  public maxdateDS= moment(this.dayx.setHours(16,30,0)).format();
  constructor( @Inject(PLATFORM_ID) private platformId: any,
   
    private platform: Platform,
    //private splashScreen: SplashScreen,
    //private statusBar: StatusBar,
    private pokeService: PokemonService,
    private titleService: Title, 
    private metaService: Meta,
    private router: Router,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    public breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
    
    { nativeElement }: ElementRef<HTMLImageElement>

   
  ) {

    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    //this.initializeApp();
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/iframe-booking') {
          this.showmenu = false;
        } else {
          // console.log("NU")
          this.showmenu = true;
        }
      }
    });

    const supports = 'loading' in HTMLImageElement.prototype;

    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
    } else {
      // fallback to IntersectionObserver
    }
   
  }
  
  initializeApp() {
    this.platform.ready().then(() => {
      //this.statusBar.styleDefault();
      //this.splashScreen.hide();

      if (this.platform.is('cordova')) {
        this.setupPush();
      }
    });
  }
onClick(event){
    let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
    systemDark.addListener(this.colorTest);
    if(event.detail.checked){
      document.body.setAttribute('data-theme', 'dark');
    }
    else{
      document.body.setAttribute('data-theme', 'light');
    }
  }

   colorTest(systemInitiatedDark) {
    if (systemInitiatedDark.matches) {
      document.body.setAttribute('data-theme', 'dark');		
    } else {
      document.body.setAttribute('data-theme', 'light');
    }
  }
  
  ngOnInit() {
    this.loadPokemon();
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute)
 
       /* rt.data.subscribe(data => {
          console.log(data);
          this.titleService.setTitle(data.title)
 
          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }
 
          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }
 
          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }
 
          if (data.ogdescrption) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogdescrption })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }
 
 
        })
        */
 
      })
     
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ]).subscribe(result => {
        if (result.breakpoints[Breakpoints.XSmall]) {
         // handle XSmall breakpoint
        }
        if (result.breakpoints[Breakpoints.Small]) {
         // handle Small breakpoint
        }
        if (result.breakpoints[Breakpoints.Medium]) {
        // handle Medium breakpoint
        }
        if (result.breakpoints[Breakpoints.Large]) {
          // handle Large breakpoint
        }
        if (result.breakpoints[Breakpoints.XLarge]) {
          // handle XLarge breakpoint
        }
      });
   this.loadbookdata();

   if(this.isBrowser)
   console.log('App loaded on browser!');
 else if(this.isServer)
   console.log('App loaded on server!');
 else
   console.log('App loaded!');
  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }
  loadPokemon(loadMore = false, event?) {
    if (loadMore) {
      this.offset += 25;
    }
 
    this.pokeService.getMenu(this.offset).subscribe(res => {
      this.pokemon = [...this.pokemon, ...res];
 
      if (event) {
        event.target.complete();
      }
 
      
    });

    this.pokeService.getAlert().subscribe(res => {
      if(res['count']>0){
        this.alert = res['results'];
      }
    });
  }
  
    onSearchChange(e) {
    let value = e.detail.value;
 
    if (value == '') {
      this.offset = 0;
      this.loadPokemon();
      return;
    }
 
    this.pokeService.findPokemon(value).subscribe(res => {
      this.pokemon = [res];
    }, err => {
      this.pokemon = [];
    });
  }
	

  ionViewWillEnter() {
    setTimeout(() => {
      this.loadPokemon() 
    }, 5000);
  }
  footer = GlobalConstants.sitefooter;



  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit('1e2d194f-1f4c-4c61-9901-9c4ddcb6d615', '717074468213');
 
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
 
    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      let msg = data.payload.body;
      let title = data.payload.title;
      let additionalData = data.payload.additionalData;
      this.showAlert(title, msg, additionalData.task);
    });
 
    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      let additionalData = data.notification.payload.additionalData;
 
      this.showAlert('Notification opened', 'You already read this before', additionalData.task);
    });
 
    this.oneSignal.endInit();
  }
 
  async showAlert(title, msg, task) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `Action: ${task}`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    })
    alert.present();
  }
  //book
  loadbookdata(){
    //console.log("loadbook");
    this.pokeService.getRCMLocation('au').subscribe(res => {
      //console.log("load book data");
      this.pokeService.getBookingSetting().subscribe(sett => {
        this.agexx=sett['enable-age'];
        if(this.agexx==0){
          this.stepAll['age'] =res['results']['driverages'][0]['id'];
        }
      });
      this.Age=res['results']['driverages'];
      let location = res['results']['locations'];
      //console.log("loc",res['results']['locations']);
      for (let i in location) {
        if(location[i]['id']==55 ||location[i]['id']==54 ||location[i]['id']==52){
          this.booklocation.push(location[i]);
        }
      };
      
      let defaultid='0';
      //console.log("bookloc",JSON.stringify(this.booklocation));
      var locparams="";
      locparams=this.deflocval.replace("-", " ");
      var locx = this.titleCase(locparams);
      //console.log("locparams:"+locx);
      this.booklocation.forEach(function (value) {
        if(locparams!=""){
          if(value['location']==locx){
            //defaultid=value['id'];
          }
        } else {
          if(value['isdefault']==true){
            //defaultid=value['id'];
          }
        }
      });
      if(defaultid!='0'){
        this.stepAll.dropoffloc=defaultid;
        this.stepAll.pickuploc=defaultid;
        //console.log("stepAllm:"+JSON.stringify(this.stepAll));
      }
    });
  }
  gotostep2all(form){
    
    this.stepAll.pickupdate=moment(this.stepAll.pickupdatem).format('DD/MM/YYYY');
    this.stepAll.pickuptime=moment(this.stepAll.pickuptimem).format("HH_mm");
    this.stepAll.dropoffdate=moment(this.stepAll.dropoffdatem).format('DD/MM/YYYY');
    this.stepAll.dropofftime=moment(this.stepAll.dropofftimem).format("HH_mm");
    this.stepAll.pickupdatem=moment(this.stepAll.pickupdatem).format();
    this.stepAll.dropoffdatem=moment(this.stepAll.dropoffdatem).format();
    console.log(this.stepAll);
    if(this.stepAll.dropoffdatem>this.stepAll.pickupdatem){
      let navigationExtras: NavigationExtras = {
        state: {
          data: this.stepAll
        }
      };
      this.showform=false;
      this.router.navigate(['bookings-select-vehicles'], navigationExtras);
    }
}
changetglds(event: MatDatepickerInputEvent<Date>) {
  //this.stepAll.dropoffdate=moment(event.value).add(8, 'd').format();
  this.stepAll.dropoffdatem=moment(event.value).add(8, 'd').format();
}
  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
  }
  @HostListener("ionScroll", ['$event'])
onWindowScroll(event) {
  //console.log('scrolling:'+event.detail.scrollTop);
  if (event.detail.scrollTop > 350) {
    this.showform=true;
  }else{
    this.showform=false;
  }
}



}
