import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module'; 
import { Geolocation } from '@ionic-native/geolocation/ngx';      
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { LazyLoadImageModule } from 'ng-lazyload-image'; // <-- import it
import { OneSignal } from '@ionic-native/onesignal/ngx';
import {TransferHttpCacheModule} from '@nguniversal/common';

import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';

import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule} from "@angular/material/form-field";
import {MatNativeDateModule,MAT_DATE_LOCALE} from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import {MatInputModule} from '@angular/material/input'; 
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { HeaderDesktopModule } from './component/header-desktop/header-desktop.module';
import { HeaderFloatModule } from './component/header-float/header-float.module';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SwiperModule } from 'swiper/angular';
@NgModule({
  declarations: [AppComponent,PagenotfoundComponent],
  entryComponents: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    IonicModule.forRoot(),
    TransferHttpCacheModule,
    AppRoutingModule,
    SlickCarouselModule,
    LazyLoadImageModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    NgxSkeletonLoaderModule,
    OwlNativeDateTimeModule,
    HeaderDesktopModule,
    HeaderFloatModule,
    MatDatepickerModule,MatFormFieldModule,MatNativeDateModule,MatMomentDateModule,MatInputModule,
    NgxIntlTelInputModule,
    SwiperModule,
   ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
 
  ],
  providers: [
    Meta,
    Geolocation,
    StatusBar,
    SplashScreen,
    CookieService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
   OneSignal
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
