<div>
<ion-header
  no-border
  class="hidden-sm hidden-xs bayangan"
  *ngIf="showmenu"
>
  <div class="header__city mobile-hide no-banner">
    <!--<div class="site-header__inner site-header__banner" style="text-align:center;">  
  <div class="alert">
    <span class="closebtn" onclick="this.parentElement.style.display='none';"><i class="fas fa-times-circle"></i></span> 
    <a href="https://www.wickedcampers.com.au/images/stories/Wicked-Campers-2020_EBook.pdf" style="color:white;">
      <i class="fas fa-exclamation-circle"></i>
     Current COVID-19 Information
   </a>
  </div>      
  </div>-->

    <!--<div class="container"  >
      <div class="d-flex">
        <div class="mr-auto">
          <a class="header__city__link" href="/">Australia</a><a class="header__city__link" href="https://www.wickedcampers.co.nz">New Zealand</a><a class="header__city__link" href="https://www.wickedcampers.co.uk">Europe</a><a class="header__city__link" href="https://www.wickedcampers.ca">North America</a><a class="header__city__link" href="https://www.wickedsouthamerica.com">South America</a><a class="header__city__link" href="https://www.wickedafrica.com">Africa</a><a class="header__city__link" href="https://www.wicked-campers.co.jp">Japan</a>
        </div>
        <div class="ml-auto">
        <a class="header__city__link_left reg" href="https://bookings.wickedcampers.com.au/checkin/">Check-In</a>
        <a onclick="bookfocus()" class="header__city__link_right reg-book" style="text-decoration: none;cursor: pointer;">Book</a>
        </div>
      </div>
    </div>-->
  </div>
  <!--<div class="">
      <div class="">
    <div class="d-flex justify-content-between">
      <div class="geser bd-highlight hidden-md"></div>
      
      <div class="bd-highlight">
        <div class="logo" routerDirection="root" [routerLink]="'/'" lines="none">
          
          <picture>
            <source srcset="../../assets/img/wickedwhite.png" type="image/webp">
            <source srcset="../../assets/img/wickedwhite.png" type="image/jpeg"> 
            <img width="100%" height="52px" data-src="image.jpg" alt="campervan hire Australia">
          </picture>
        
       </div>
      </div>
      <div class="bd-highlight hidden-md">
        <nav>
             
             <ul class="menu">
            <li auto-hide="true" *ngFor="let p of pokemon; let i = index" class="hassubs podsx">
              <a href="{{p.url}}"><ion-item (click)="selectedIndex = i" routerDirection="root" lines="none" detail="false" [class.selected]="selectedIndex == i" class="bold">
              <ion-label>{{ p.title }}</ion-label>
              </ion-item>
              </a>
              <ul class="sub-menu">
                <li auto-hide="true" *ngFor="let xx of p.sub;" class="subs podsx">
                  <a href="{{xx.url}}">
                  <ion-item (click)="selectedIndex = i" routerDirection="root" lines="none" detail="false" [class.selected]="" class="bold">
                  {{ xx.title }}
                  </ion-item>
                  </a>
                  <ul *ngIf="xx.sub != '[]'" class="pods">
                   
                    <li auto-hide="true" *ngFor="let aa of xx.sub;">
                      <a href="{{aa.url}}">
                      <ion-item (click)="selectedIndex = i" routerDirection="root" lines="none" detail="false" [class.selected]="selectedIndex == i" class="bold">
                      {{aa.title}}
                    </ion-item>
                    </a>
                  </li>
                  </ul>
                  
                </li>
              </ul>
          </li>
            </ul>
            </nav>
      </div>
      <div class="bd-highlight hidden-md">
        <ul class="menukanan">
          <li auto-hide="true" class="hassubs podsx header-right-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16"><g><g><path fill="#fff" d="M11.774 10.758c-.506-.5-1.137-.5-1.64 0-.384.38-.767.76-1.144 1.147-.103.107-.19.13-.316.058-.248-.135-.512-.245-.75-.393-1.113-.7-2.044-1.598-2.87-2.61-.408-.503-.773-1.041-1.027-1.647-.052-.122-.042-.203.058-.303.383-.37.757-.75 1.134-1.131.526-.529.526-1.147-.003-1.68-.3-.302-.6-.599-.9-.901-.309-.31-.615-.622-.927-.929-.506-.493-1.138-.493-1.64.004-.387.38-.758.77-1.151 1.144a1.867 1.867 0 0 0-.587 1.26c-.061.802.136 1.56.413 2.297.567 1.528 1.43 2.885 2.478 4.129a15.336 15.336 0 0 0 5.08 3.973c.889.432 1.81.764 2.813.819.69.039 1.289-.136 1.769-.674.329-.367.7-.702 1.047-1.054.516-.522.52-1.153.007-1.669-.613-.615-1.228-1.228-1.844-1.84z"></path></g><g><path fill="#fff" d="M14.73 7L16 6.823c-.2-.954-.75-1.817-1.586-2.504-.885-.723-2.003-1.178-3.235-1.319L11 4.044c.953.11 1.82.46 2.505 1.02.647.53 1.07 1.199 1.225 1.936z"></path></g><g><path fill="#fff" d="M16.745 2.35A7.936 7.936 0 0 0 12.153 0L12 1.12a6.881 6.881 0 0 1 3.968 2.03A7.183 7.183 0 0 1 17.914 7L19 6.81a8.351 8.351 0 0 0-2.255-4.46z"></path></g></g></svg> 1800 24 68 69 
            
        </li>
          <li auto-hide="true" class="hassubs podsx header-right-item chekin">
            <a href="/online-checkin"><i class="far fa-sign-in-alt"></i> Check-In</a>
            
        </li>
        
          </ul>
      </div>
      
    
      <div class="index_top_r"> </div>
    </div>
  </div>
</div>-->
<div *ngFor="let al of alert;" class="hidden-lg hidden-print fixed d-none text-center" [ngClass]="{ 'd-block': showform }">
  <div class="travel-alert-banner">
    <p [innerHTML]="al.value"></p>
  </div>
</div>
  <div
    id="booking-form"
    class="fixed d-none"
    loading="lazy"
    style="height: auto; background: #ffb700"
    [ngClass]="{ 'd-block': showform }"
  >
  
    <form
      #stepAllform="ngForm"
      (ngSubmit)="stepAllform.form.valid && gotostep2all(0)"
      class="row justify-content-center"
    >
      <div class="col filter-item pickups">
        <select
          name="pickuplocation"
          [(ngModel)]="stepAll.pickuploc"
          #pickuploc="ngModel"
          class="form-control"
        >
          <option class="filter-label" value="0">Pickup Location</option>
          <option *ngFor="let field of booklocation" value="{{ field.id }}">
            {{ field.location }}
          </option>
        </select>
      </div>
      <div class="col filter-item drop">
        <select
          name="dropofflocation"
          class="form-control"
          [(ngModel)]="stepAll.dropoffloc"
          #dropoffloc="ngModel"
          required
          autocomplete="off"
          style="width: 100%"
        >
          <option class="filter-label" value="0">Return Location</option>
          <option *ngFor="let field of booklocation" value="{{ field.id }}">
            {{ field.location }}
          </option>
        </select>
      </div>
      <div class="col filter-item tgl">
        <input
          matInput
          [matDatepicker]="picker"
          (click)="picker.open()"
          (dateInput)="changetglds($event)"
          (dateChange)="changetglds($event)"
          [ngClass]="{
            'ds-invalid': stepAllform.submitted && pickupdate.invalid
          }"
          name="pickupdate"
          [(ngModel)]="stepAll.pickupdatem"
          placeholder="Pickup Date"
          #pickupdate="ngModel"
          class="form-control"
          id="txtPickup"
          autocomplete="off"
          style="width: 100%; border: none"
          required
          [min]="minDate"
        />

        <mat-datepicker #picker></mat-datepicker>
      </div>

      <div class="col filter-item wkts">
        <input
          id="txtPickupTime"
          [ngClass]="{
            'ds-invalid': stepAllform.submitted && pickuptime.invalid
          }"
          type="text"
          step="1800"
          name="pickuptime"
          placeholder="Pickup Time"
          class="form-control"
          id="txtPickupTime"
          autocomplete="off"
          style="width: 100%; border: none"
          [(ngModel)]="stepAll.pickuptimem"
          #pickuptime="ngModel"
          required
          required
          [owlDateTimeTrigger]="dt2"
          [owlDateTime]="dt2"
        />
        <owl-date-time
          [startAt]="mindateDS"
          [stepMinute]="30"
          [pickerType]="'timer'"
          #dt2
        ></owl-date-time>
      </div>

      <div class="col filter-item tgl">
        <input
          matInput
          [matDatepicker]="pickerx"
          (click)="pickerx.open()"
          [ngClass]="{
            'ds-invalid':
              (stepAllform.submitted && pickupdate.invalid) ||
              (stepAllform.submitted &&
                stepAll.dropoffdatem <= stepAll.pickupdatem)
          }"
          name="returndate"
          [(ngModel)]="stepAll.dropoffdatem"
          placeholder="Return Date"
          #pickupdate="ngModel"
          class="form-control"
          id="txtReturn"
          autocomplete="off"
          style="width: 100%; border: none"
          required
          [min]="minDate"
        />
        <mat-datepicker #pickerx></mat-datepicker>
      </div>

      <div class="col filter-item wkts">
        <input
          id="txtReturnTime"
          [ngClass]="{
            'ds-invalid': stepAllform.submitted && dropofftime.invalid
          }"
          type="text"
          name="dropofftime"
          placeholder="Return Time"
          class="form-control"
          id="txtReturnTime"
          autocomplete="off"
          style="width: 100%; border: none"
          [(ngModel)]="stepAll.dropofftimem"
          #dropofftime="ngModel"
          required
          [owlDateTimeTrigger]="dt"
          [owlDateTime]="dt"
        />
        <owl-date-time
          [startAt]="mindateDS"
          [stepMinute]="30"
          [pickerType]="'timer'"
          #dt
        ></owl-date-time>
      </div>
      <div class="col filter-item">
                 <input type="text" name="form-Promo-Code" placeholder="Promo Code" class="form-control" id="txtPromoCode" autocomplete="off"  [(ngModel)]="stepAll.voucher">
             </div>

      <div class="col filter-item carii">
        <button class="cari btn btn-primary btn-sm" type="submit">
          Search
        </button>
      </div>
      <div class="col-12">
        <div
          *ngIf="stepAllform.submitted && stepAllform.form.invalid"
          style="color: white"
        >
          Please fill required field
        </div>
       <div
          *ngIf="
            stepAllform.submitted && stepAll.dropoffdatem <= stepAll.pickupdatem
          "
          style="color: white"
        >
          Return date must be after Pickup date
        </div>
      </div>
    </form>
    <!--<app-header-float></app-header-float>-->

  </div>
</ion-header>
<ion-router-outlet></ion-router-outlet>
</div>